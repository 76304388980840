<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>车辆列表</el-breadcrumb-item>
      <el-breadcrumb-item>车辆使用类型</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    内容开始-->
    <el-card class="box-card">
      <div class="top">
        <div class="top_one">
          <div v-for="item in usetypelist" :key="item.id" class="top-a">
            <el-button @click="btn(item)">{{ item.tname }}</el-button>
            <div class="ppp">
              <div @click="btns(ss.id)" :v-if="flag" v-for="ss in toplist" :key="ss.id">{{ ss.tname }}</div>
            </div>
          </div>
        </div>

        <div class="top_two">
          <el-input style="width: 300px" placeholder="请输入内容" v-model="sosuotext" class="input-with-select" @keyup.enter.native="sosuofun()" clearable @clear="handleEmptyno">
            <el-button slot="append" icon="el-icon-search" @click="sosuofun()"></el-button>
          </el-input>
          <el-button style="margin-right: 15px" type="primary" @click="opentan()">添加信息</el-button>
        </div>
      </div>
      <!--    列表开始-->
      <!-- <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="auto_number" label="车牌号"></el-table-column>
        <el-table-column label="车辆性质">
          <template slot-scope="scope">
            <span v-if="scope.row.type * 1 === 1">运营车辆</span>
            <span v-else-if="scope.row.type * 1 === 2">管理车辆</span>
          </template>
        </el-table-column>
        <el-table-column label="车辆类型">
          <template slot-scope="scope">
            {{ scope.row.get_use_type ? scope.row.get_use_type.tname : '无' }}
          </template>
        </el-table-column>
        <el-table-column label="使用单位">
          <template slot-scope="scope">
            {{ scope.row.get_current_org ? scope.row.get_current_org.node_name : '无' }}
          </template>
        </el-table-column>
        <el-table-column label="使用方式">
          <template slot-scope="scope">
            <span v-if="scope.row.type * 1 === 1">
              <span v-if="scope.row.use_method * 1 === 3">自有</span>
              <span v-else-if="scope.row.use_method * 1 === 1">租赁</span>
              <span v-else-if="scope.row.use_method * 1 === 2">免费</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px"> -->
      <!-- <template slot-scope="scope"> -->
      <!-- 修改按钮 -->
      <!-- <el-button size="mini" type="primary" icon="el-icon-edit" @click="editfun(scope.row)"></el-button> -->
      <!-- <el-button size="mini" type="danger" icon="el-icon-delete" @click="delfun(scope.row.id)"></el-button> -->
      <!-- </template> -->
      <!-- </el-table-column> -->
      <!-- </el-table> -->

      <!-- 主体 -->
      <div class="her">
        <div class="list" v-for="item in tableData" :key="item.id">
          <div class="lis_left">
            <img :src="imgUrl(item)" alt="" />
            <div class="andan">
              <button @click="tiao(item.id)" class="btnss">车辆详情</button>
              <button @click="editfun(item)" class="btnss">修改</button>
            </div>
          </div>
          <div class="list_right">
            <ul class="list_ul">
              <li>车辆类型</li>
              <li>车辆牌号</li>
              <li>使用单位</li>
              <li>归属单位</li>
            </ul>
            <ul class="list_ul">
              <li v-if="item.get_use_type != null && item.type == 1">{{ item.get_use_type.tname }}</li>
              <li v-if="item.type == 2">管理车辆</li>
              <li>{{ item.auto_number }}</li>
              <li>{{ item.get_current_org ? item.get_current_org.node_name : '无' }}</li>
              <li>{{ item.get_ownership ? item.get_ownership.owner_name : '无' }}</li>
            </ul>
          </div>
        </div>
      </div>

      <!--      分页-->
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!--  弹窗内容-->
    <el-dialog title="车辆信息" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <el-form :rules="drules" ref="dfrom" :model="dform" label-width="90px" label-position="left">
        <el-row :gutter="4">
          <el-col :span="8">
            <!--            根据车俩性质修改要展示的项目-->
            <el-form-item label="车辆性质" prop="type">
              <el-radio-group v-model="dform.type" @change="changeTheme">
                <el-radio label="1">运营车辆</el-radio>
                <el-radio label="2">管理车辆</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col v-if="dform.type === '1'" :span="8">
            <el-form-item label="车辆类型" prop="property">
              <el-cascader ref="tree" @change="optpidfun" v-model="aa" :options="usetypelist" clearable :props="casprops"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col v-if="dform.type === '1'" :span="8">
            <el-form-item label="使用方式">
              <el-radio-group v-model="dform.use_method">
                <el-radio label="3">自有</el-radio>
                <el-radio label="1">租赁</el-radio>
                <el-radio label="2">免费</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="车辆牌号" prop="auto_number">
              <div class="el-select"><el-input v-model="dform.auto_number"></el-input></div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!--            下拉-->
            <el-form-item label="使用单位" prop="shiyong">
              <el-select v-model="dform.current_org" placeholder="使用单位">
                <el-option v-for="item in pronodelist" :key="item.id" :label="item.node_name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="责任人" prop="zernre">
              <el-autocomplete
                :trigger-on-focus="false"
                placeholder="请输入内容"
                v-model="dform.liableidname"
                :fetch-suggestions="validateCounts"
                @select="handleSelect($event, '责任人')"
              >
                <template slot-scope="{ item }">
                  <div class="name">{{ (item.value = item.user_name) }}</div>
                </template>
              </el-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="dform.type === '1'">
          <el-col :span="8">
            <!--            下拉-->
            <el-form-item label="车辆所有权" prop="propertyinfo">
              <el-select v-model="dform.owner_type">
                <el-option v-for="item in carquan" :key="item.id" :label="item.owner_name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!--            下拉-->
            <el-form-item label="归属单位">
              <el-select v-model="dform.org_id" placeholder="归属单位">
                <el-option v-for="item in pronodelist" :key="item.id" :label="item.node_name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="使用人">
              <el-autocomplete
                :trigger-on-focus="false"
                placeholder="请输入内容"
                v-model="dform.operidname"
                :fetch-suggestions="validateCounts"
                @select="handleSelect($event, '使用人')"
              >
                <template slot-scope="{ item }">
                  <div class="name">{{ (item.value = item.user_name) }}</div>
                </template>
              </el-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="dform.type === '1'">
          <el-col :span="8">
            <el-form-item label="品牌制造商">
              <el-select v-model="dform.producer" placeholder="品牌制造商">
                <el-option v-for="item in pinlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="上装制造商">
              <el-select v-model="dform.shangzhuang_brand" placeholder="上装制造商">
                <el-option v-for="item in pinlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="底盘制造商">
              <el-select v-model="dform.dipan_brand" placeholder="底盘制造商">
                <el-option v-for="item in pinlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!--          <el-col :span='8'>-->
          <!--            <el-form-item label="型号">-->
          <!--              <div class='el-select'> <el-input v-model='dform.shangzhuang_brand'></el-input></div>-->

          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <el-col :span="8">
            <el-form-item label="总质量">
              <div class="el-select">
                <el-input v-model="dform.auto_weight">
                  <i slot="suffix" class="iconfont icon-zhongliang"></i>
                </el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="载质量">
              <div class="el-select">
                <el-input v-model="dform.capacity_weight">
                  <i slot="suffix" class="iconfont icon-zhongliang"></i>
                </el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="垃圾分类">
              <el-select v-model="dform.trashtype_id">
                <el-option v-for="item in trashtypeData" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="dform.type === '1'">
          <el-col :span="8">
            <el-form-item label="车架号" prop="shelf">
              <div class="el-select"><el-input v-model="dform.frame_no"></el-input></div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="车辆型号">
              <div class="el-select"><el-input v-model="dform.type_no"></el-input></div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发动机号">
              <div class="el-select"><el-input v-model="dform.motor_no"></el-input></div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="dform.type === '1'">
          <el-col :span="8">
            <el-form-item label="品牌">
              <el-select v-model="dform.brand" placeholder="行驶证品牌">
                <el-option v-for="item in brandData" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="上牌时间" v-if="dform.use_method !== '1'">
              <el-date-picker v-model="dform.register_time" type="date" value-format="yyyy-MM-dd" placeholder="初次上牌时间"></el-date-picker>
            </el-form-item>
            <el-form-item label="开始日期" v-if="dform.use_method === '1'">
              <el-date-picker v-model="dform.start_time_at" type="date" value-format="yyyy-MM-dd" placeholder="租赁开始日期"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="购置日期" prop="date" v-if="dform.use_method !== '1'">
              <el-date-picker v-model="dform.buy_in" type="date" value-format="yyyy-MM-dd" placeholder="买入时间"></el-date-picker>
            </el-form-item>
            <el-form-item label="租赁时长" prop="date" v-if="dform.use_method === '1'">
              <div class="el-select"><el-input v-model="dform.lease_month" placeholder="租赁时长（月）"></el-input></div>
            </el-form-item>
          </el-col>
          <!--          <el-col :span='8'>-->
          <!--            <el-form-item label="卖出时间">-->
          <!--              <el-date-picker-->
          <!--                v-model="dform.sell_out"-->
          <!--                type="date"-->
          <!--                value-format="yyyy-MM-dd"-->
          <!--                placeholder="卖出时间">-->
          <!--              </el-date-picker>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
        </el-row>
        <el-row v-if="dform.type === '1'">
          <el-col :span="8">
            <el-form-item v-if="dform.use_method !== '1'" label="购置金额">
              <div class="el-select">
                <el-input v-model="dform.fapiao_money" placeholder="购置金额（含税）">
                  >
                  <i slot="prefix" class="iconfont icon-jiage-copy"></i>
                </el-input>
              </div>
            </el-form-item>
            <el-form-item v-if="dform.use_method === '1'" label="租赁金额">
              <div class="el-select">
                <el-input v-model="dform.lease_money" placeholder="租赁金额（月）">
                  >
                  <i slot="prefix" class="iconfont icon-jiage-copy"></i>
                </el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="dform.use_method !== '1'">
            <el-form-item label="税金">
              <div class="el-select">
                <el-input v-model="dform.gouzhishui_money">
                  <i slot="prefix" class="iconfont icon-jiage-copy"></i>
                </el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="dform.use_method !== '1'">
            <el-form-item label="上牌费">
              <div class="el-select">
                <el-input v-model="dform.shangpai_money">
                  <i slot="prefix" class="iconfont icon-jiage-copy"></i>
                </el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" v-if="dform.type === '1'">
            <el-form-item label="设备编号">
              <div class="el-select">
                <el-input v-model="dform.sim_card"></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item v-if="dform.use_method !== '1'" label="购买发票" prop="icon">
              <el-upload
                accept=".pdf, .jpg, .jpeg, .png"
                class="upload-demo"
                action=""
                :on-preview="handlePreview"
                :on-remove="
                  (file, fileList) => {
                    handleRemove(file, fileList, this.dform.goumai_fapiao)
                  }
                "
                :before-remove="beforeRemove"
                :http-request="httpRequest"
                :multiple="false"
                :limit="8"
                :on-exceed="handleExceed"
                :file-list="dform.goumai_fapiao"
              >
                <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('购买发票')">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item v-if="dform.use_method === '1'" label="租赁合同" prop="icon">
              <el-upload
                accept=".pdf, .jpg, .jpeg, .png"
                class="upload-demo"
                action=""
                :on-preview="handlePreview"
                :on-remove="
                  (file, fileList) => {
                    handleRemove(file, fileList, this.dform.hetong)
                  }
                "
                :before-remove="beforeRemove"
                :http-request="httpRequest"
                :multiple="false"
                :limit="8"
                :on-exceed="handleExceed"
                :file-list="dform.hetong"
              >
                <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('租赁合同')">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="dform.use_method !== '1'">
            <el-form-item v-if="dform.type === '1'" label="车辆照片" prop="icon">
              <el-upload
                accept=".pdf, .jpg, .jpeg, .png"
                class="upload-demo"
                action=""
                :on-preview="handlePreview"
                :on-remove="
                  (file, fileList) => {
                    handleRemove(file, fileList, this.dform.auto_photo)
                  }
                "
                :before-remove="beforeRemove"
                :http-request="httpRequest"
                :multiple="false"
                :limit="8"
                :on-exceed="handleExceed"
                :file-list="dform.auto_photo"
              >
                <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('车辆照片')">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="dform.use_method === '1'">
            <el-form-item label="租赁发票" prop="icon">
              <el-upload
                accept=".pdf, .jpg, .jpeg, .png"
                class="upload-demo"
                action=""
                :on-preview="handlePreview"
                :on-remove="
                  (file, fileList) => {
                    handleRemove(file, fileList, this.dform.fapiao)
                  }
                "
                :before-remove="beforeRemove"
                :http-request="httpRequest"
                :multiple="false"
                :limit="8"
                :on-exceed="handleExceed"
                :file-list="dform.fapiao"
              >
                <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('租赁发票')">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="汽车蓝本" prop="icon">
              <el-upload
                accept=".pdf, .jpg, .jpeg, .png"
                class="upload-demo"
                action=""
                :on-preview="handlePreview"
                :on-remove="
                  (file, fileList) => {
                    handleRemove(file, fileList, this.dform.lanben_files)
                  }
                "
                :before-remove="beforeRemove"
                :http-request="httpRequest"
                :multiple="false"
                :limit="8"
                :on-exceed="handleExceed"
                :file-list="dform.lanben_files"
              >
                <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('汽车蓝本')">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item v-if="dform.type === '1'" label="汽车大本" prop="icon">
              <el-upload
                accept=".pdf, .jpg, .jpeg, .png"
                class="upload-demo"
                action=""
                :on-preview="handlePreview"
                :on-remove="
                  (file, fileList) => {
                    handleRemove(file, fileList, this.dform.daben_files)
                  }
                "
                :before-remove="beforeRemove"
                :http-request="httpRequest"
                :multiple="false"
                :limit="8"
                :on-exceed="handleExceed"
                :file-list="dform.daben_files"
              >
                <el-button :disabled="addimgtype" size="small" type="primary" @click="upyupefun('汽车大本')">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 管理车辆显示的表单项 -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelfun()">取 消</el-button>
        <el-button :disabled="addimgtype" v-if="tantype !== 'look'" type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import { Addauto, Delauto, EditAuto, GetautoList, Getproducerlist, GetUseTypeList,Ownerships } from '../../../api/car'
import { GetBraandsList } from '@/api/vehicle/autoconfigure'
import { Serusersass, Setpronodelist } from '@/api/user'
import { Upimg } from '@/api/upimg'

export default {
  components: {
    Fenye
  },
  data() {
    return {
      type_two: '', // 车辆类型筛选
      sosuotext: '', // 搜索
      aa: [3],
      flag: false,
      addimgtype: false, // 上传控制
      casprops: { value: 'id', label: 'tname', checkStrictly: false }, // 级联选择的配置
      options: [],
      trashtypeData: [
        { id: 132, name: '可回收物' },
        { id: 148, name: '厨余垃圾' },
        { id: 149, name: '有害垃圾' },
        { id: 150, name: '其他垃圾' }
      ], // 作业类型
      // 车辆所有权
      // carquan: [
      //   { id: 1, name: '北宅街道' },
      //   { id: 2, name: '个人' },
      //   { id: 3, name: '嘉美' },
      //   { id: 4, name: '嘉美烟台' },
      //   { id: 5, name: '嘉美枣庄' },
      //   { id: 6, name: '柳新镇政府' },
      //   { id: 7, name: '王哥庄街道' },
      //   { id: 8, name: '健康城' }
      // ],
      carquan:[],
      dialogVisible: false, // 弹窗控制
      dform: {
        type: '1',
        use_method: 3,
        goumai_fapiao: [], // 购买发票
        auto_photo: [], // 车辆照片
        lanben_files: [], // 汽车蓝本
        daben_files: [], // 汽车大本
        hetong: [], // 租赁合同
        fapiao: [], // 租赁发票
        sim_card: '' // 设备编号
      },
      // 弹窗按钮的状态
      tantype: 'add',
      // 验证规则
      drules: {
        type: [{ required: true, message: '请选择车辆性质', trigger: 'change' }],
        auto_number: [{ required: true, message: '请输入车牌号', trigger: 'change' }]
        // shiyong:[
        //   { required: true, message: '请选择车辆使用单位', trigger: 'change' }
        // ],
        // zernre:[
        //   { required: true, message: '请选择责任人', trigger: 'change' }
        // ],
      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 9,
        // 共有多少条
        count: 0
      },
      // 列表数据
      tableData: [],
      // 使用类型列表
      usetypelist: [],
      // 品牌列表
      brandData: [],
      // 人员列表
      userlist: [],
      // 图片上传值
      steupdata: '',
      // 品牌商门
      pinlist: [],
      // 项目列表
      pronodelist: [],
      toplist: [] // ////////
    }
  },
  mounted() {
    this.getownershiplistfun()
    this.getusetypelistfun()
    this.Ownershipss()
  },
  methods: {
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.getownershiplistfun()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.getownershiplistfun()
    },
    editfun(ev) {
      // 打开编辑
      // 赋值
      this.dform = JSON.parse(JSON.stringify(ev))
      this.dform.type = this.dform.type + ''
      this.dform.use_method = this.dform.use_method + ''
      this.dform.fapiao_money = this.dform.fapiao_money !== undefined ? this.dform.fapiao_money / 100 : 0
      this.dform.gouzhishui_money = this.dform.gouzhishui_money !== undefined ? this.dform.gouzhishui_money / 100 : 0
      this.dform.shangpai_money = this.dform.shangpai_money !== undefined ? this.dform.shangpai_money / 100 : 0
      this.dform.lease_money = this.dform.lease_money !== undefined ? this.dform.lease_money / 100 : 0
      console.log(this.dform)
      // 修改属性为修改
      this.tantype = 'edit'
      // 打开弹窗
      this.opentan()
    },
    lookfun(ld) {
      // 打开查看
      // 修改属性为修改
      this.tantype = 'look'
      // 打开弹窗
      this.opentan()
    },
    delfun(id) {
      // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delownershipfun(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 打开弹窗
    opentan() {
      this.dialogVisible = true
      // 获取使用类型
      this.getusetypelistfun()
      // 获取品牌
      this.getbrandlistfun()
    },
    // eslint-disable-next-line no-unused-vars
    // 删除图片
    handleRemove(file, fileList, re) {
      re.forEach((item, i) => {
        if (item.uid === file.uid) {
          re.splice(i, 1)
        }
      })
    },
    // eslint-disable-next-line no-unused-vars
    handlePreview(file) {},
    // eslint-disable-next-line no-unused-vars
    handleExceed(files, fileList) {
      // this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 删除
    beforeRemove(file, fileList, re) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 切换上传条件
    upyupefun(v) {
      this.steupdata = v
    },
    // 图片上传
    async httpRequest(options) {
      // console.log(options)
      this.addimgtype = true
      const fd = new FormData()
      fd.append('file[]', options.file)
      const res = await Upimg(fd)
      switch (this.steupdata) {
        case '购买发票':
          this.dform.goumai_fapiao.push({ name: '购买发票', url: res.data[0].url })
          break
        case '车辆照片':
          this.dform.auto_photo.push({ name: '车辆照片', url: res.data[0].url })

          break
        case '汽车蓝本':
          this.dform.lanben_files.push({ name: '汽车蓝本', url: res.data[0].url })

          break
        case '汽车大本':
          this.dform.daben_files.push({ name: '汽车大本', url: res.data[0].url })

          break
        case '租赁合同':
          this.dform.hetong.push({ name: '租赁合同', url: res.data[0].url })

          break
        case '租赁发票':
          this.dform.fapiao.push({ name: '租赁发票', url: res.data[0].url })

          break
      }
      this.addimgtype = false

      // console.log(this.fileList)
      // // 图片上传成功后把图片push到scroll中用于修改
      // console.log(res)
    },
    // 选择级联中的值
    optpidfun(e) {
      this.dform.use_type_three = e[e.length - 1] === undefined ? 0 : e[e.length - 1]
    },
    // 选中
    handleSelect(e, term) {
      switch (term) {
        case '使用人':
          this.dform.operator_uid = e.id
          break
        case '责任人':
          this.dform.liable_uid = e.id
          break
      }
    },
    // 搜索后的数据
    validateCounts(queryString, cb) {
      // 调用方法
      this.setuserfun(queryString, cb)
    },
    // 确定添加
    addgoto(type) {
      switch (type) {
        case 'add':
          this.$refs.dfrom.validate(v => {
            if (v) {
              this.addownershipfun(this.dform)

              // this.$nextTick(() => {
              //   this.$refs[dform].resetFields()
              // })
            }
          })
          break
        case 'edit':
          this.$refs.dfrom.validate(v => {
            if (v) {
              this.editownershipfun(this.dform)
            }
          })

          break
      }
    },
    // 取消
    cancelfun() {
      this.handleClose()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.dform = {
        type: '1',
        use_method: 0,
        goumai_fapiao: [], // 购买发票
        auto_photo: [], // 车辆照片
        lanben_files: [], // 汽车蓝本
        daben_files: [], // 汽车大本
        hetong: [], // 租赁合同
        fapiao: [] // 租赁发票
      }
      this.$refs.tree.$refs.panel.clearCheckedNodes()
      this.tantype = 'add'
    },
    // 渲染图片处理 没有图片显示默认图片
    imgUrl(item) {
      if (item.auto_photo.length == 0) {
        return 'https://qiniu.haiwojiamei.com/upload/meijia/20221227/68e37e715df777c549074ba5f77dd662.png'
      } else {
        return item.auto_photo[0].url
      }
    },
    // 车型
    btn(e) {
      // console.log(e);
      this.toplist = e.children
    },
    btns(e) {
      this.type_two = e
      this.getownershiplistfun()

      console.log(e)
    },
    // 跳转详情页
    tiao(id) {
      console.log(id)
      this.$router.push({
        path: `/carindetail?id=${id}`
      })
    },
    // 搜索
    async sosuofun() {
      const { data } = await GetautoList({ key: this.sosuotext, ...this.pageData })
      this.getanpostfun(data, '获取')
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    handleEmptyno(){
      this.sosuotext = ''
      this.sosuofun()
    },
    changeTheme(val) {
      console.log(val)
      // if (val == 2) {
      //   this.dform.aa = '' // 车辆类型
      //   this.dform.owner_type = '' // 车辆所有权
      //   this.dform.org_id = '' // 归属单位
      //   this.dform.operidname = '' ////使用人
      //   this.dform.producer = '' // 品牌制造商
      //   this.dform.shangzhuang_brand = '' // 上装制造商
      //   this.dform.dipan_brand = '' // 底盘制造商
      //   this.dform.auto_weight = '' // 总质量
      //   this.dform.capacity_weight = '' // 载质量
      //   this.dform.trashtype_id = '' // 垃圾分类
      //   this.dform.frame_no = '' // 车架号
      //   this.dform.type_no = '' // 车辆型号
      //   this.dform.motor_no = '' // 发动机号
      //   this.dform.brand = '' //  品牌
      //   this.dform.register_time = '' // 上牌时间
      //   this.dform.start_time_at = '' // 开始日期
      //   this.dform.buy_in = '' // 购置日期
      //   this.dform.lease_month = '' // 租赁时长
      //   this.dform.fapiao_money = '' // 购置金额
      //   this.dform.lease_money = '' // 租赁金额
      //   this.dform.gouzhishui_money = '' // 税金
      //   this.dform.shangpai_money = '' // 上牌费
      //   this.dform.sim_card = '' // 设备编号
      //   this.dform.hetong = '' // 租赁合同
      //   this.dform.auto_photo = '' // 车辆照片
      //   this.dform.fapiao = '' // 租赁发票
      //   this.dform.daben_files = '' //汽车大本
      // }
    },

    // 请求区ლ(´ڡ`ლ)
    // 获取车辆
    async getownershiplistfun() {
      const { data } = await GetautoList({ ...this.pageData, type_two: this.type_two })
      this.getanpostfun(data, '获取')
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },
    // 添加车辆信息
    async addownershipfun(v) {
      console.log(v)
      var newdata = JSON.parse(JSON.stringify(v))
      newdata.fapiao_money = newdata.fapiao_money !== undefined ? newdata.fapiao_money * 100 : 0
      newdata.gouzhishui_money = newdata.gouzhishui_money !== undefined ? newdata.gouzhishui_money * 100 : 0
      newdata.shangpai_money = newdata.shangpai_money !== undefined ? newdata.shangpai_money * 100 : 0
      newdata.lease_money = newdata.lease_money !== undefined ? newdata.lease_money * 100 : 0
      const { data } = await Addauto(newdata)

      this.getanpostfun(data, '添加', this.getownershiplistfun())
    },
    // 删除车辆信息
    async delownershipfun(id) {
      const { data } = await Delauto({ id })
      this.getanpostfun(data, '删除', this.getownershiplistfun())
    },
    async editownershipfun(ev) {
      var newdata = JSON.parse(JSON.stringify(ev))
      newdata.fapiao_money = newdata.fapiao_money !== undefined ? newdata.fapiao_money * 100 : 0
      newdata.gouzhishui_money = newdata.gouzhishui_money !== undefined ? newdata.gouzhishui_money * 100 : 0
      newdata.shangpai_money = newdata.shangpai_money !== undefined ? newdata.shangpai_money * 100 : 0
      newdata.lease_money = newdata.lease_money !== undefined ? newdata.lease_money * 100 : 0
      const { data } = await EditAuto(newdata)
      this.getanpostfun(data, '修改', this.getownershiplistfun())
    },
    // 获取使用类型
    async getusetypelistfun() {
      const { data } = await GetUseTypeList({ page: 1, size: 10000 })
      // console.log(data);
      this.usetypelist = data.data.list
      this.toplist = data.data.list[0].children
    },
    // 获取品牌
    async getbrandlistfun() {
      const { data } = await GetBraandsList({ page: 1, size: 10000 })
      this.brandData = data.data.list
      // 获取车商
      const { data: men } = await Getproducerlist({ list_type: 'all', status: 1 })
      this.pinlist = men.data.list
      // 获取项目
      const { data: mu } = await Setpronodelist({ tree_id: 6 })
      this.pronodelist = mu.data
    },
    // 获取人
    async setuserfun(key, cd) {
      const { data } = await Serusersass({ key })
      this.userlist = data.data.info
      cd(data.data.info)
    },

    async Ownershipss(){
      const {data} = await Ownerships()
      console.log(data,'data');
      this.carquan = data.data
    },
    // 请求区结束Ψ(￣∀￣)Ψ
    // 公共方法区└(^o^)┘└(^o^)┘
    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>
<style scoped>
.top {
  display: flex;
  height: 60px;
  justify-content: space-between;
  position: relative;
}
.top_two {
  width: 800px;
}
.top_one {
  display: flex;
}
.top-a {
  margin-right: 25px;
  font-size: 16px;
}
.ppp {
  display: flex;
  font-size: 14px;
  position: absolute;
  top: 35px;
  left: 0px;
  margin-left: 10px;
}
.ppp div {
  margin-top: 5px;
  margin-right: 15px;
  cursor: pointer;
}

/* .her{
  width: 500px;
  border: 1px solid #000;
} */
.list_ul li {
  list-style: none;
  width: 100px;
  /* border: 1px solid #999; */
  border-bottom: 1px solid #999;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 40px;
}
.list_ul {
  padding: 0px;
  text-align: center;
}
.list {
  width: 460px !important;
  /* border: 1px solid #999; */
  /* box-shadow: -5px -5px 17px -3px rgb(0 0 0 / 28%); */
  box-shadow: 1px 1px 15px -3px rgb(0 0 0 / 28%);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
}
.list_right {
  display: flex;
}
.her {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 30px;
}
img {
  width: 220px;
  height: 124px;
  margin-top: 15px;
}
.andan {
  width: 200px;
  display: flex;
  justify-content: space-around;
}
.lis_left {
  margin-right: 10px;
}
.btnss {
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 5px 8px;
  color: #666;
}
.btnss:hover {
  background-color: #66b1ff;
  color: white;
  font-weight: bold;
}
.list:hover {
  box-shadow: -5px -5px 17px -3px rgb(0 0 0 / 28%);
}
</style>
